import React from "react"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../layouts/layout'
import { Helmet } from 'react-helmet'

import Style from './contact.module.scss'
import { graphql } from "gatsby";
import Title from "../components/Title";

const Contact = () => {
    const {t} = useTranslation();
    return (
        <div>
            <Layout>
                <Helmet>
                    <title>{t("contact_title")}</title>
                </Helmet>

                <Title translation="contact_h1"/>

                <div className={Style.wrapper}>
                    <form name="contact-tbcom" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
                        <input aria-label="Bot" type="hidden" name="bot-field" />
                        <input aria-label="Netlify" type="hidden" name="form-name" value="contact-tbcom" />
                        <p>
                            <label htmlFor="contact-name" className={Style.label_form}>{t("contact_name")} <input aria-label="Name" className={Style.input_form} type="text" name="name" id="contact-name" required/></label>
                        </p>
                        <p>
                            <label htmlFor="contact-email" className={Style.label_form}>{t("contact_email")} <input aria-label="Email" className={Style.input_form} type="email" name="email" id="contact-email" required/></label>
                        </p>
                        <p>
                            <label htmlFor="contact-message" className={Style.label_form}>{t("contact_msg")} <textarea aria-label="Message" className={Style.input_form} name="message" id="contact-message"></textarea></label>
                        </p>
                        <p>
                            <button aria-label="Submit" type="submit">{t("contact_send")}</button>
                        </p>
                    </form>
                </div>
            </Layout>
        </div>
    )
}

export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;